import { useEffect, useState } from "react";
import { useOrganizationStore } from "@/app/stores/organizationStore";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import CreditPulseScoreRadialWheel from "@/app/components/CreditPulseScoreRadialWheel/CreditPulseScoreRadialWheel";
import { DropdownMenu } from "@/app/components/DropdownMenu/DropdownMenu";
import { DotIndicator } from "@/app/components/DotIndicator/DotIndicator";

interface DbtData {
  name: string; // Format: "Month Year"
  industryDbt: number;
  averageDbt: number;
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    // Sort payload by the 'name' property to ensure consistent order
    const sortedPayload = payload.sort((a, b) => {
      // Check if either name is undefined and handle it safely
      if (!a.name || !b.name) return 0;
      return a.name > b.name ? 1 : -1;
    });

    return (
      <div className="custom-tooltip bg-white p-2 shadow-md rounded">
        <p className="label">{`Date: ${label}`}</p>
        {sortedPayload.map((entry, index) => (
          <p key={index} className="intro" style={{ color: entry.color }}>
            {`${entry.name}: ${entry?.value?.toFixed(1)}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const PortfolioRiskGraph = () => {
  const [data, setData] = useState<DbtData[]>([]);
  const [timeFrame, setTimeFrame] = useState(12);
  const [percentChange, setPercentChange] = useState(0);

  const { organizationProfile } = useOrganizationStore();

  useEffect(() => {
    if (organizationProfile && organizationProfile.overallRiskSummaries.length) {
      const transformedData = transformData(
        organizationProfile.overallRiskSummaries,
        timeFrame
      );
      setData(transformedData);

      if (transformedData.length >= 2) {
        const latest = transformedData[0].averageDbt;
        const previous = transformedData[1].averageDbt; // The month before the latest

        if (previous !== 0) {
          // To avoid division by zero
          const change = ((latest - previous) / previous) * 100;
          setPercentChange(Math.round(change)); // Round to the nearest integer
        }
      }
    }
  }, [organizationProfile, timeFrame]);

  const transformData = (riskSummaries: any[], months: number): DbtData[] => {
    const monthsLabels = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let chartData: DbtData[] = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    for (let i = months - 1; i >= 0; i--) {
      const monthIndex = (currentMonth - i + 12) % 12;
      const year = currentMonth - i < 0 ? currentYear - 1 : currentYear;
      const monthName = `${monthsLabels[monthIndex]} ${year}`;

      const monthlyEntry = riskSummaries
        .find((risk) => {
          return risk.date === `${year}-${String(monthIndex + 1).padStart(2, '0')}`;
        });

      chartData.push({
        name: monthName,
        industryDbt: monthlyEntry?.industryDbt || 0,
        averageDbt: monthlyEntry?.averageDbt || 0,
      });
    }

    return chartData;
  };

  const renderPercentageString = (value: number) => {
    if (value < 0) {
      return <span className="font-bold text-red-400">{value}%</span>;
    }
    if (value > 0) {
      return <span className="font-bold text-green-400">{value}%</span>;
    } else {
      return <span className="font-bold text-secondary-text">{value}%</span>;
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between">
        <h3 className="font-bold">Portfolio DBT</h3>
        <DropdownMenu
          className="pl-10 ml-10"
          menuItems={[{ name: "Trailing 12M", value: 12 }, { name: "Trailing 6M", value: 6 }]}
          selectedItem={timeFrame}
          asButton={true}
          onMenuChoiceChange={setTimeFrame}
        />
      </div>
      <div className="flex flex-row">
        <span className="block text-secondary-text">
          {renderPercentageString(percentChange)} Last 30 Days
        </span>
      </div>
      <div className="my-5 text-right">
        {/* <div className="basis-1/3">
          <CreditPulseScoreRadialWheel
            score={organizationProfile?.overallRiskSummaries?.[0]?.averageCreditPulseScore || 0}
          />
        </div> */}
        <div>
          <div>
            Industry DBT
            <DotIndicator
              color="bg-infographic-primary-green"
              className="mt-1 ml-2"
            />
          </div>
          <div>
            Average Portfolio DBT
            <DotIndicator
              color="bg-infographic-secondary-green"
              className="mt-1 ml-2"
            />
          </div>
          </div>
      </div>
      {/* <div className="basis-2/3 flex items-end mb-8">
        <span className="block text-secondary-text">
          {renderPercentageString(percentChange)} Last 30 Days
        </span>
      </div> */}
      <div style={{ width: "100%", height: "200px" }}>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="industryDbt"
              name="Industry DBT"
              stroke="#748F9B"
              dot={false}
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="averageDbt"
              name="Average DBT"
              stroke="#93BECB"
              dot={false}
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default PortfolioRiskGraph;
