import React from "react";
import classNames from "classnames"; // Import the classNames utility

export type PanelProps = {
  primary?: boolean;
  children?: any;
  className?: string;
  innerFormatting?: string;
};

const Panel = ({
  primary = true,
  className,
  innerFormatting = "px-4 py-5 sm:p-6",
  children,
}: PanelProps) => {
  const containerClassName = classNames(
    "storybook-panel",
    innerFormatting,
    className
  );

  return (
    <div
      className={`overflow-visible rounded-md bg-white  border border-gray-300 ${className}`}
    >
      <div className={`storybook-panel ${innerFormatting}`}>{children}</div>
    </div>
  );
};

export default Panel;
