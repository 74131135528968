import LoadingAnimation from "@/app/components/LoadingAnimation/LoadingAnimation";
import { useOrganizationStore } from "@/app/stores/organizationStore";

const MiniRiskLevelTable = () => {
  // Get organization Profile
  const { organizationProfile } = useOrganizationStore();

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(value));
  };

  if (!organizationProfile) return <LoadingAnimation />;

  if (!organizationProfile?.overallRiskSummaries?.length) return <p>No Data</p>;

  return (
    <div>
      <div className="flex flex-row gap-2 my-1">
        <div className="font-bold text-xs basis-1/4 ">
          <span>Risk Level</span>
          <div className="mt-1">
            <span className="inline-flex items-center text-xs font-medium bg-green-100 px-2 py-1 mx-1 text-green-700 ring-1 ring-inset ring-green-700/10 rounded-full">
              Low
            </span>
          </div>
          <div className="my-1">
            <span className="inline-flex items-center text-xs font-medium bg-yellow-100 px-2 py-1 mx-1 text-yellow-700 ring-1 ring-inset ring-yellow-700/10 rounded-full">
              Medium
            </span>
          </div>
          <div>
            <span className="inline-flex items-center text-xs font-medium bg-red-100 px-2 py-1 mx-1 text-red-700 ring-1 ring-inset ring-pink-700/10 rounded-full">
              High
            </span>
          </div>
        </div>
        <div className="font-bold text-xs basis-1/4">
          Accts
          <div className=" my-1 py-1 mx-2">
            {organizationProfile?.overallRiskSummaries[0]?.low || 0}
          </div>
          <div className="my-1 py-1 mx-2">
            {organizationProfile?.overallRiskSummaries[0]?.medium || 0}
          </div>
          <div className=" my-1 py-1 mx-2">
            {organizationProfile?.overallRiskSummaries[0]?.high || 0}
          </div>
        </div>

        <div className="font-bold text-xs basis-1/4">
          Utilization
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.lowCreditUtilized || 0
            )}
          </div>
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.mediumCreditUtilized || 0
            )}
          </div>
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.highCreditUtilized || 0
            )}
          </div>
        </div>
        <div className="font-bold text-xs basis-1/4">
          Extended
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.lowCreditExtended || 0
            )}
          </div>
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.mediumCreditExtended || 0
            )}
          </div>
          <div className="pl-4 my-1 py-1 mx-2">
            {formatCurrency(
              organizationProfile?.overallRiskSummaries[0]
                ?.highCreditExtended || 0
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniRiskLevelTable;
