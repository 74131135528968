import React from "react";
import Image from "next/image";

export type CreditPulseScoreRadialWheel = {
  score: number;
} & React.HTMLAttributes<HTMLDivElement>;

export function CreditPulseScoreRadialWheel({
  children,
  onClick,
  score = 1,
  ...rest
}: CreditPulseScoreRadialWheel) {
  let imageLocation = "";

  switch (score) {
    case 1:
      imageLocation = "/assets/components/radialWheels/value-1.png";
      break;
    case 2:
      imageLocation = "/assets/components/radialWheels/value-2.png";
      break;
    case 3:
      imageLocation = "/assets/components/radialWheels/value-3.png";
      break;
    case 4:
      imageLocation = "/assets/components/radialWheels/value-4.png";
      break;
    case 5:
      imageLocation = "/assets/components/radialWheels/value-5.png";
      break;
    case 6:
      imageLocation = "/assets/components/radialWheels/value-6.png";
      break;
    case 7:
      imageLocation = "/assets/components/radialWheels/value-7.png";
      break;
    case 8:
      imageLocation = "/assets/components/radialWheels/value-8.png";
      break;
    case 9:
      imageLocation = "/assets/components/radialWheels/value-9.png";
      break;
    case 10:
      imageLocation = "/assets/components/radialWheels/value-10.png";
      break;
  }

  return (
    <>
      <div>
        <Image
          src={imageLocation}
          alt="Pulse Radial Wheel Background"
          width={300}
          height={300}
        />
      </div>
    </>
  );
}

export default CreditPulseScoreRadialWheel;
