import { defaultInfographicColors } from "@/app/lib/design/infographicStyles";
import { Tooltip } from "react-tooltip";

export type Segment = {
  value: number;
  color?: string;
  label: string;
};

export type HorizontalSegmentedBarProps = {
  segments: Segment[];
};

const HorizontalSegmentedBar = ({ segments }: HorizontalSegmentedBarProps) => {
  // Calculate total value of all segments
  const totalValue = segments.reduce((acc, segment) => acc + segment.value, 0);
  // Standard colours if no colours are provided

  return (
    <div className="flex flex-row h-6 bg-gray-300 rounded-lg w-full">
      <Tooltip id="HorizontalSegmentedBar" />
      {segments.map((segment, index) => {
        const width = `${(segment.value / totalValue) * 100}%`;
        // Determine additional classes for rounded corners on first and last items
        const additionalClasses = [
          index === 0 ? "rounded-l-lg" : "",
          index === segments.length - 1 ? "rounded-r-lg" : "",
        ].join(" ");
        // Use default color if no color is provided, cycling through the defaultColors array
        const colorClass =
          segment.color ||
          defaultInfographicColors[index % defaultInfographicColors.length];

        return (
          <div
            data-tooltip-id="HorizontalSegmentedBar"
            data-tooltip-content={segment.label}
            data-tooltip-place="top"
            key={index}
            className={`flex-none h-full ${colorClass} ${additionalClasses}`}
            style={{ width }}
          ></div>
        );
      })}
    </div>
  );
};

export default HorizontalSegmentedBar;
