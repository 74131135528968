import { CompanySearchDTOType } from "../hooks/clientHook";
import authAxiosInstance from "../lib/interceptors/apiInterceptor";
import authGqlAxiosInstance from "../lib/interceptors/gqlApiInterceptor";

class ClientService {
  async getAllClientsByOrganizationId({
    organizationId,
  }: {
    organizationId: string;
  }): Promise<any> {
    try {
      const response = await authAxiosInstance.get(
        `/organization/${organizationId}/clients`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getClientByClientId(clientId: number): Promise<any> {
    try {
      const response = await authAxiosInstance.get(`/client/${clientId}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async searchInternalDatabaseForClient({
    clientSearchDTO,
  }: {
    clientSearchDTO: any;
  }): Promise<any> {
    console.log("clientSearchDTO to service", clientSearchDTO);
    try {
      const response = await authAxiosInstance.post(`/client`, {
        ...clientSearchDTO,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async companySearch(data: CompanySearchDTOType): Promise<any> {
    try {
      // let apiUrl = `/client/companySearch`;
      // if (data.company_name) {
      //   apiUrl += `?name=${encodeURIComponent(data.company_name)}`;
      // }
      // if (data.company_address) {
      //   apiUrl += `&address=${encodeURIComponent(data.company_address)}`;
      // }
      // if (data.company_city) {
      //   apiUrl += `&city=${encodeURIComponent(data.company_city)}`;
      // }
      // if (data.company_province) {
      //   apiUrl += `&province=${encodeURIComponent(data.company_province)}`;
      // }
      // if (data.company_zip_code) {
      //   apiUrl += `&postcode=${encodeURIComponent(data.company_zip_code)}`;
      // }
      // if (data.company_url) {
      //   apiUrl += `&website=${encodeURIComponent(data.company_url)}`;
      // }
      // if (data.company_countries) {
      //   apiUrl += `&countries=${encodeURIComponent(data.company_countries)}`;
      // }

      // const response = await authAxiosInstance.post(apiUrl);
      // return response.data;

      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `query searchCompanies($name: String, $website: String, $street: String, $city: String, $province: String, $countries: [String], $postCode: String, $page: Int, $pageSize: Int) {
            searchCompanies(name: $name, website: $website, street: $street, city, : $city, province: $province, countries: $countries, postCode: $postCode, page: $page, pageSize: $pageSize) {
              totalSize
              companies {
                companySearchId
                name
                websites
                address
                status
                officeType
              }
            }
          }`,
          operationName: 'searchCompanies',
          variables: {
            name: data.company_name,
            street: data.company_address,
            city: data.company_city,
            province: data.company_province,
            postCode: data.company_zip_code,
            countries: data.company_countries?.split(','),
            website: data.company_url,
          },
        },
      });
      return response?.data?.data?.searchCompanies?.companies;
    } catch (error) {
      throw error;
    }
  }

  async createNewClient({ orgId, connectId, groupId }: { orgId: string, connectId: string, groupId?: string }): Promise<any> {
    try {
      // const response = await authAxiosInstance.post(
      //   `/client/${connectId}`,
      //   connectId
      // );
      // return response.data;
      const response = await authGqlAxiosInstance({
        method: 'post',
        data: {
          query: `mutation createAccount($orgId: ID!, $groupId: ID, $companySearchId: ID!) {
            createAccount(orgId: $orgId, groupId: $groupId, companySearchId: $companySearchId)
          }`,
          operationName: 'createAccount',
          variables: {
            orgId,
            companySearchId: connectId,
            groupId,
          },
        },
      });
      return response?.data?.data?.createAccount;
    } catch (error) {
      console.error("Failed to create new client:", error);
      throw error;
    }
  }
}

export default new ClientService();
