import React from "react";

export type ProgressBarProps = {
  primary?: boolean;
  totalValue: number;
  currentValue: number;
  backgroundColor?: string; // Assume this is a valid CSS color now, not a Tailwind class
  progressColor?: string; // Also a valid CSS color
};

export function ProgressBar({
  totalValue,
  currentValue,
  primary = true,
  backgroundColor = "bg-gray-400", // Default to CSS color gray
  progressColor = "red-900", // Default color for progress
}: ProgressBarProps) {
  const mode = primary
    ? "storybook-progressBar--primary"
    : "storybook-progressBar--secondary";

  // Calculate the width of the progress bar based on the current and total values
  const progressWidth = `${(currentValue / totalValue) * 100}%`;

  return (
    <div className={["storybook-progressBar", "w-full", mode].join(" ")}>
      <div className={`h-7 w-full ${backgroundColor} rounded-md mt-3`}>
        <div
          style={{
            height: "100%",
            width: progressWidth,
            transition: "width 1s ease-in-out",
          }}
          className={`rounded-l-md ${progressColor}`} // Rounded left edges only
        ></div>
      </div>
    </div>
  );
}
