// Helper function to check if a date is in the current month
export const isThisMonth = (date: any) => {
  const now = new Date();
  const givenDate = new Date(date);
  return (
    givenDate.getMonth() === now.getMonth() &&
    givenDate.getFullYear() === now.getFullYear()
  );
};

// Helper function to check if a date is in the current year
export const isThisYear = (date: any) => {
  const now = new Date();
  const givenDate = new Date(date);
  return givenDate.getFullYear() === now.getFullYear();
};

// Helper function to check if a date is more than a year old
export const isOverAYearOld = (date: any) => {
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  return new Date(date) < oneYearAgo;
};

export function getFutureDateInMonths(
  months: number,
  dayType: "first" | "last"
): string {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + months);

  if (dayType === "first") {
    currentDate.setDate(1); // Sets to the first day of the month
  } else if (dayType === "last") {
    currentDate.setMonth(currentDate.getMonth() + 1); // Move to the next month
    currentDate.setDate(0); // Sets to the last day of the previous month
  }

  // Format the date as YYYY-MM-DD
  const year = currentDate.getFullYear();
  // Adding 1 to getMonth() because JavaScript months are 0-indexed
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

export function generateMonthArray(monthsAhead: number): string[] {
  const currentDate = new Date();
  let monthList: string[] = [];

  for (let monthOffset = 0; monthOffset < monthsAhead; monthOffset++) {
    // Calculate future date
    let futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + monthOffset,
      currentDate.getDate()
    );
    // Format the month and year
    let monthYearFormat = futureDate.toLocaleString("default", {
      month: "short",
      year: "numeric",
    });

    if (!monthList.includes(monthYearFormat)) {
      monthList.push(monthYearFormat);
    }
  }

  return monthList;
}

export function getFirstDayOfNextMonth() {
  const currentDate = new Date(); // Gets today's date
  const currentMonth = currentDate.getMonth(); // Gets the current month (0-11)
  const currentYear = currentDate.getFullYear(); // Gets the current year

  // Create a new date object for the first day of the next month
  const firstDayNextMonth = new Date(currentYear, currentMonth + 1, 1);

  // Manually construct the date string in "DD-MM-YYYY" format
  const day = ("0" + firstDayNextMonth.getDate()).slice(-2);
  const month = ("0" + (firstDayNextMonth.getMonth() + 1)).slice(-2); // +1 because months are zero-indexed
  const year = firstDayNextMonth.getFullYear();

  const dateString = `${month}-${day}-${year}`;

  return dateString;
}

export function ISOToReadableDate(date: string) {
  const dateObj = new Date(date);
  return dateObj.toLocaleDateString();
}
