import React, { ReactNode } from "react";

export type DotIndicatorProps = {
  children?: ReactNode;
  primary?: boolean;
  className?: string;
  color?: string; // Tailwind class name or hex color
} & React.HTMLAttributes<HTMLSpanElement>;

export function DotIndicator({
  primary = true,
  color = "bg-infographic-primary-green",
  children,
  className,
  ...rest
}: DotIndicatorProps) {
  const mode = primary
    ? "storybook-dotIndicator--primary"
    : "storybook-dotIndicator--secondary";

  // Check if the color prop is a hex color and prepare it for Tailwind CSS
  const adjustedColor = color.startsWith("#") ? `bg-[${color}]` : color;

  // Combine all class names including the color handling
  const tailwindClasses = `inline-block h-3 w-3 rounded-full ${adjustedColor}`;
  const combinedClassName = [
    "storybook-dotIndicator",
    mode,
    tailwindClasses,
    className, // User provided class name (if any)
  ]
    .filter(Boolean)
    .join(" "); // Filter out any falsey values and join with spaces

  return <span className={combinedClassName} {...rest}></span>;
}
