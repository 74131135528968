export const defaultInfographicColors = [
  "bg-[#2E2C34]",
  "bg-[#5542F6]",
  "bg-[#00A5FF]",
  "bg-[#20C9AC]",
  "bg-[#FFA043]",
  "bg-[#FC3400]",
  "bg-[#FA699D]",
  "bg-[#A259FF]",
];
