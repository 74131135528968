"use client";

import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { useOrganizationStore } from "@/app/stores/organizationStore";
import CustomPercentLabel from "../../../../components/GraphingComponents/CustomPercentLabel";

const LABEL_BY_VALUE: Record<string, any> = {
  low: <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">Low</span>,
  medium: <span className="inline-flex items-center rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700 mx-2">Medium</span>,
  high: <span className="inline-flex items-center rounded-full bg-pink-100 px-2 py-1 text-xs font-medium text-pink-700">High</span>,
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white">
        <p>{label}</p>
        {payload.reverse().map((item) => {
          const color = (item.name === 'low') ? 'green' : (item.name === 'medium') ? 'yellow' : 'red';
          const ringColor = (item.name === 'low') ? 'green' : (item.name === 'medium') ? 'yellow' : 'pink';
          return (
            <div
              key={item.dataKey}
              className={`items-center w-fit rounded-full bg-${color}-100 px-2 py-1 my-2 text-xs font-medium text-${color}-700 ring-1 ring-inset ring-${ringColor}-700/10`}
            >
              {item.value} {(!!item.name) && (item.name.charAt(0).toUpperCase() + item.name.slice(1))}
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

interface RiskData {
  name: string;
  high: number;
  medium: number;
  low: number;
}

const RiskSummaryGraph = () => {
  const [data, setData] = useState<RiskData[]>([]);
  const { organizationProfile } = useOrganizationStore();

  useEffect(() => {
    if (organizationProfile) {
      const allRiskSummaries = organizationProfile.overallRiskSummaries;
      const transformedData = transformData(allRiskSummaries);
      setData(transformedData);
    }
  }, [organizationProfile]);

  const transformData = (overallRiskSummary: any[]): RiskData[] => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let monthData: RiskData[] = [];

    for (let i = 12; i >= 0; i--) {
      const monthIndex = (currentMonth - i + 12) % 12;
      const monthYear = currentMonth - i < 0 ? currentYear - 1 : currentYear;
      const monthName = `${months[monthIndex]} ${monthYear}`;

      const foundData = overallRiskSummary.find((d) => {
        return d.date === `${monthYear}-${String(monthIndex + 1).padStart(2, '0')}`;
      });

      monthData.push({
        name: monthName,
        high: foundData ? foundData.high : 0,
        medium: foundData ? foundData.medium : 0,
        low: foundData ? foundData.low : 0,
      });
    }

    return monthData;
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart data={data}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Bar
          dataKey="high"
          stackId="a"
          fill="#f05452"
          //TODO: FIX CUstom label issue
          //label={(props) => <CustomPercentLabel {...props} data={data} />}
        />
        <Bar
          dataKey="medium"
          stackId="a"
          fill="#fce76b"
          //label={(props) => <CustomPercentLabel {...props} data={data} />}
        />
        <Bar
          dataKey="low"
          stackId="a"
          fill="#84e1bd"
          //label={(props) => <CustomPercentLabel {...props} data={data} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default RiskSummaryGraph;
