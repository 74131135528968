import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import { Fragment } from "react";
import {
  Menu,
  Transition,
  MenuButton,
  MenuItems,
  MenuItem,
} from "@headlessui/react";
import { XClose, ChevronDown } from "@untitled-ui/icons-react";

export type MenuItem = {
  name: string;
  value: any;
  icon?: ReactNode;
};

export type DropdownMenuProps = {
  primary?: boolean;
  className?: string;
  menuItems?: MenuItem[];
  styleColor?: string;
  orientation?: "left" | "right";
  onMenuChoiceChange: (choice: any) => void;
  selectedItem?: any;
  placeHolderText?: string;
  clearable?: boolean;
  showIndicator?: boolean;
  asButton?: boolean;
  selectedItemView?: "name" | "icon" | "name+icon";
  buttonView?: ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

export function DropdownMenu({
  primary = true,
  styleColor = "red-500",
  orientation = "right",
  menuItems = [],
  onMenuChoiceChange,
  selectedItem,
  placeHolderText = "Select Item",
  clearable = false,
  showIndicator = false,
  asButton = false,
  selectedItemView = "name",
  buttonView,
}: DropdownMenuProps) {
  const mode = primary
    ? "storybook-dropdownMenu--primary"
    : "storybook-dropdownMenu--secondary";

  const handleMenuItemClick = (value: any) => {
    onMenuChoiceChange(value);
  };

  const buttonClasses = asButton
    ? "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
    : "w-full flex";
  const tailwindClasses = `relative text-left`;
  const menuTailwindClasses = `${orientation}-0 absolute z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-80 overflow-y-auto`;
  const menuItemTailwindClasses = `hover:text-[${styleColor}]`;
  const selectedItemName = selectedItem
    ? menuItems.find((item) => item.value === selectedItem)?.name
    : placeHolderText;
  const selectedItemIcon = selectedItem
    ? menuItems.find((item) => item.value === selectedItem)?.icon
    : null;
  return (
    <Menu
      as="div"
      className={["storybook-dropdownMenu", tailwindClasses, mode].join(" ")}
    >
      <div className={buttonClasses}>
        <MenuButton className="text-left flex-grow">
          {buttonView}
          {!buttonView && (
            <div className="flex items-center w-full ">
              {selectedItemView === "name+icon" && (
                <span className="flex-grow">
                  {selectedItemIcon} {selectedItemName}
                </span>
              )}
              {selectedItemView === "name" && (
                <span className="flex-grow">{selectedItemName}</span>
              )}
              {selectedItemView === "icon" && (
                <span className="flex-grow">{selectedItemIcon}</span>
              )}
              {showIndicator && (
                <span className="ml-2">
                  <ChevronDown className="text-gray-500" />
                </span>
              )}
            </div>
          )}
        </MenuButton>
        {clearable && selectedItem && (
          <span data-testid="clear-button" className="ml-2 cursor-pointer">
            <XClose
              className="text-gray-500"
              onClick={() => onMenuChoiceChange("")}
            />
          </span>
        )}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className={[menuTailwindClasses].join(" ")}>
          <div className="py-1">
            {menuItems.map((item, index) => (
              <MenuItem key={index}>
                {({ active }) => (
                  <span
                    className={classNames(
                      [
                        "px-4 py-2 text-sm flex items-center",
                        active
                          ? `bg-gray-100 text-gray-900 hover:text-${styleColor}`
                          : "text-gray-700",
                        menuItemTailwindClasses,
                      ].join(" ")
                    )}
                    onClick={() => handleMenuItemClick(item.value)}
                  >
                    {primary && item.icon && (
                      <span className="mr-2">{item.icon}</span>
                    )}
                    {item.name}
                  </span>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
